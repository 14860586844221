import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import LoadingDots from 'components/LoadingDots'
import SearchCard from './SearchCard'
import styles from './SearchResult.module.sass'

import { actions } from 'modules/home/HomeReducer'
import {
  getSearch,
  getSearchTerm,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'

import { getProfileInfo } from 'utils'
import config from 'site.config'

const {
  configuration: { searchResult }
} = config
const pageSize = searchResult.query.pagination.maxResults

const SearchResult = props => {
  const dispatch = useDispatch()
  const [timer, setTimer] = useState(0)

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  const searchTerm = useSelector(getSearchTerm)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState([])
  const {
    data,
    loading: isLoading,
    pages,
    total,
    currentPage: currentFetchedPage
  } = useSelector(getSearch)
  const isLastPage = currentPage === pages
  const isLastRecord = search.length === total

  useEffect(() => {
    const isCurrentPageDataFetched = currentFetchedPage === currentPage
    const isLatestDataAddedToList = search.length < currentPage * pageSize

    if (
      !isLoading &&
      isCurrentPageDataFetched &&
      isLatestDataAddedToList &&
      !isLastRecord
    ) {
      if (currentPage === 1) setSearch([...data])
      else setSearch([...search, ...data])
    }
  }, [search, currentPage, currentFetchedPage, data, isLastRecord, isLoading])

  useEffect(() => {
    if (!searchTerm) return
    if (currentPage > pages) return

    setCurrentPage(1)
    let payload = { searchTerm, currentPage: 1 }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (timer) clearTimeout(timer)
    const newTimer = setTimeout(() => {
      setSearch([])
      dispatch(actions.requestSearch(payload))
    }, 300)
    setTimer(newTimer)

    return () => clearTimeout(newTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm])

  useEffect(() => {
    if (!searchTerm) return
    if (currentPage > pages) return
    if (currentPage === 1) return

    let payload = { searchTerm, currentPage }
    dispatch(actions.requestSearch(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    window.onscroll = () => {
      const bottomOfWindow =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight
      if (!isLoading && bottomOfWindow && !isLastPage && currentPage < pages) {
        setCurrentPage(currentPage + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isLastPage, isLoading])

  return (
    <div className={styles.container}>
      <Link
        onClick={() => {
          dispatch(actions.setSearchTerm(''))
          dispatch(actions.setSearchPanel(false))
        }}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            dispatch(actions.setSearchTerm(''))
            dispatch(actions.setSearchPanel(false))
          }
        }}
        to={'/'}
      >
        <p
          className={styles.backTxt}
          dangerouslySetInnerHTML={{ __html: '< Back' }}
        />
      </Link>
      <h2>Search Result(s)</h2>
      <h3>Medical Education from Answers in CME</h3>
      {search?.map(({ title, teaser, extra_fields }) => {
        return (
          <SearchCard
            key={extra_fields.project_number}
            thumbnail={
              extra_fields.thumbnail ||
              extra_fields.icon_image ||
              'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg'
            }
            credit={Number(extra_fields.dgme_credits).toFixed(1)}
            creditType={extra_fields.dgme_credit_type}
            title={title}
            description={teaser}
            link={extra_fields.article_url + urlParams}
            alt={extra_fields.human_url}
          />
        )
      })}
      {!isLoading && !search.length && (
        <p className={styles.noresult}>No result found.</p>
      )}
      {isLoading && <LoadingDots />}
    </div>
  )
}

export default SearchResult
