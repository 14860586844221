import {
  takeLatest,
  takeEvery,
  all,
  put,
  call,
  cancelled
} from 'redux-saga/effects'
import { actions as homeActions } from './HomeReducer'
import { formQuery } from 'components/FeedBuilder'
// import { actions as notificationActions } from 'modules/notification/NotificationReducer'
// import { getProfileSpecialtyIdByLabel } from 'modules/specialty-mapping/SpecialtyMapping'
import { delay, getTimeRange, getRandomInt } from 'utils'
import config from 'site.config.json'
const {
  configuration: {
    aimatch,
    assetPath,
    interestFeed,
    newsFeed,
    searchResult,
    articleFeed,
    tracking,
    envVars
  },
  sections: {
    leftRail: { curriculaJSON, podcastJSON, eventJSON }
  }
} = config

// const showNotification = false

export function* getNewsFeed(services, { payload }) {
  const cookieService = services('CookieService')
  while (!cookieService.get('authToken')) yield delay(50)
  try {
    const authToken = JSON.parse(cookieService.get('authToken'))
    const apiService = services('APIService')
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url = baseUrl + newsFeed.path
    const isOpenSearch = false

    let query = newsFeed.query
    query.page = payload.currentPage
    // Text Search query
    // if (payload.searchTerm)
    //   query.textSearch = {
    //     text: payload.searchTerm,
    //     fields: [
    //       'title',
    //       'human_url',
    //       'extra_fields.article_url',
    //       'extra_fields.searchtext',
    //       'extra_fields.authors',
    //       'tags.conditions',
    //       'tags.specialties',
    //       'tags._all'
    //     ]
    //   }
    // else delete query.textSearch
    // Expiration date filter
    // const expiration_date = Math.floor(Date.now() / 1000)
    // query.newsFeed.must.range[
    //   'extra_fields.expiration_date'
    // ].gte = expiration_date
    // Active filters
    const { to, from } = getTimeRange(query.timeRange.duration)
    query.timeRange.to = to
    query.timeRange.from = from
    query = formQuery(payload, query, isOpenSearch)

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Getting Search Results...',
    //       duration: 0
    //     })
    //   )
    // }
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      query,
      additionalHeader
    )

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Getting Search Results Successful !',
    //       duration: 3000
    //     })
    //   )
    // }
    yield put(homeActions.requestNewsSuccess(result))
  } catch (e) {
    yield put(homeActions.requestNewsFail(e))

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Failed to fetch search results!',
    //       duration: 2000
    //     })
    //   )
    // }
  }
}

export function* getInterestFeed(services, { payload }) {
  const cookieService = services('CookieService')
  while (!cookieService.get('authToken')) yield delay(50)
  try {
    const authToken = JSON.parse(cookieService.get('authToken'))
    const apiService = services('APIService')
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url = baseUrl + interestFeed.path
    const isOpenSearch = false

    let query = interestFeed.query
    query.page = payload.currentPage
    const { to, from } = getTimeRange(query.timeRange.duration)
    query.timeRange.to = to
    query.timeRange.from = from
    query = formQuery(payload, query, isOpenSearch)

    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      query,
      additionalHeader
    )

    yield put(homeActions.requestInterestSuccess(result))
  } catch (e) {
    yield put(homeActions.requestInterestFail(e))
  }
}

export function* getSearchResult(services, { payload }) {
  const cookieService = services('CookieService')
  while (!cookieService.get('authToken')) yield delay(50)
  try {
    const authToken = JSON.parse(cookieService.get('authToken'))
    const apiService = services('APIService')
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url = baseUrl + searchResult.path

    let query = searchResult.query
    query.pagination.page = payload.currentPage
    // Text Search query
    if (payload.searchTerm)
      query.textSearch = {
        ...searchResult.query.textSearch,
        text: payload.searchTerm
      }
    else delete query.textSearch
    // Expiration date filter
    const expiration_date = Math.floor(Date.now() / 1000)
    query.searchTerms.must.range[
      'extra_fields.expiration_date'
    ].gte = expiration_date

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Getting Search Results...',
    //       duration: 0
    //     })
    //   )
    // }
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      query,
      additionalHeader
    )

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Getting Search Results Successful !',
    //       duration: 3000
    //     })
    //   )
    // }
    yield put(homeActions.requestSearchSuccess(result))
  } catch (e) {
    yield put(homeActions.requestSearchFail(e))

    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Failed to fetch Search Results!',
    //       duration: 2000
    //     })
    //   )
    // }
  }
}

export function* getArticle(services, { payload }) {
  const cookieService = services('CookieService')
  while (!cookieService.get('authToken')) yield delay(50)
  try {
    const authToken = JSON.parse(cookieService.get('authToken'))
    const apiService = services('APIService')
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url = baseUrl + articleFeed.path

    let query = articleFeed.query
    query.human_url = payload.human_url

    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      query,
      additionalHeader
    )

    yield put(homeActions.requestArticleSuccess(result))
  } catch (e) {
    yield put(homeActions.requestArticleFail(e))
  }
}

export function* getAd(services, { payload }) {
  if (!payload.type) yield cancelled()
  try {
    const apiService = services('APIService')
    const sessionService = services('SessionService')

    let url = aimatch[payload.type]
    url = url.replace(
      /<viewid>/g,
      payload?.viewid ?? getRandomInt(1000, 9999).toString()
    )
    url = url.replace(
      /<pid>/g,
      payload?.pid ?? getRandomInt(100000, 999999).toString()
    )
    url = url.replace(
      /<random>/g,
      payload?.random ?? getRandomInt(10000, 99999).toString()
    )

    const setPayloadFromCache = (payload, key, cacheKey) => {
      payload[key] = payload?.[key] || sessionService.getFromCache(cacheKey, '')
    }

    setPayloadFromCache(payload, 'partyId', 'MemberID')
    setPayloadFromCache(payload, 'partyEmailId', 'EmailID')
    setPayloadFromCache(payload, 'countryCode', 'CountryCode')
    setPayloadFromCache(payload, 'professionId', 'ProfessionID')
    if (!payload.specialtyId)
      setPayloadFromCache(payload, 'specialtyId', 'SpecialtyID')

    url = url.replace(/<mid>/g, payload.partyId || '1')
    url = url.replace(/<personid>/g, payload.partyId || '')
    url = url.replace(/<emailid>/g, payload.partyEmailId || '')
    url = url.replace(/<geography>/g, payload.countryCode || 'US')
    url = url.replace(/<prof>/g, payload.professionId || '12')
    url = url.replace(/<spec>/g, payload?.specialtyId ?? '')
    url = url.replace(/<position>/g, payload?.position ?? 'centerrail')
    url = url.replace(
      /<channel>/g,
      payload.position === 'centerrail' ? 'aicme' : 'website'
    )
    // Interaction ID
    const interactionId = sessionService
      .getFromCache(tracking.clarity.identifiers['custom-session-id'], '')
      ?.toString()
    url = url.replace(/<interactionid>/g, interactionId || '')

    const req = yield call([apiService, 'getWithoutAnyHeaders'], url, {})
    yield put(
      homeActions.requestAdSuccess({ req, extra: { random: payload.random } })
    )
  } catch (e) {
    yield put(homeActions.requestAdFail(e))
  }
}

export function* getCurricula(services) {
  try {
    const apiService = services('APIService')
    const baseUrl =
      (process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_NEML_API
        : process.env.REACT_APP_CDN_URL) +
      '/' +
      assetPath +
      '/'
    const url = baseUrl + curriculaJSON
    const req = yield call([apiService, 'getWithoutAnyHeaders'], url, {})
    yield put(homeActions.requestCurriculaSuccess(req))
  } catch (e) {
    yield put(homeActions.requestCurriculaFail(e))
  }
}

export function* getPodcasts(services) {
  try {
    const apiService = services('APIService')
    const baseUrl =
      (process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_NEML_API
        : process.env.REACT_APP_CDN_URL) +
      '/' +
      assetPath +
      '/'
    const url = baseUrl + podcastJSON
    const req = yield call([apiService, 'getWithoutAnyHeaders'], url, {})
    yield put(homeActions.requestPodcastsSuccess(req))
  } catch (e) {
    yield put(homeActions.requestPodcastsFail(e))
  }
}

export function* getEvents(services) {
  try {
    const apiService = services('APIService')
    const baseUrl =
      (process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_NEML_API
        : process.env.REACT_APP_CDN_URL) +
      '/' +
      assetPath +
      '/'
    const url = baseUrl + eventJSON
    const req = yield call([apiService, 'getWithoutAnyHeaders'], url, {})
    yield put(homeActions.requestEventsSuccess(req))
  } catch (e) {
    yield put(homeActions.requestEventsFail(e))
  }
}

export default function* watchHome(services) {
  yield all([takeLatest(homeActions.requestNews, getNewsFeed, services)])
  yield all([
    takeLatest(homeActions.requestInterest, getInterestFeed, services)
  ])
  yield all([takeLatest(homeActions.requestSearch, getSearchResult, services)])
  yield all([takeLatest(homeActions.requestArticle, getArticle, services)])
  yield all([
    takeLatest(homeActions.requestCurricula, getCurricula, services),
    takeLatest(homeActions.requestPodcasts, getPodcasts, services),
    takeLatest(homeActions.requestEvents, getEvents, services)
  ])
  yield all([takeEvery(homeActions.requestAd, getAd, services)])
}
