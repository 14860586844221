import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'modules/home/HomeReducer'
import { getSearchTerm } from 'modules/home/HomeSelector'
import styles from './SearchBar.module.sass'

const SearchBar = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [timer, setTimer] = useState(0)
  const searchTerm = useSelector(getSearchTerm)

  useEffect(() => {
    if (!searchTerm) return

    let payload = { searchTerm, currentPage: 1 }
    if (timer) clearTimeout(timer)
    const newTimer = setTimeout(
      () => dispatch(actions.requestSearch(payload)),
      300
    )
    setTimer(newTimer)

    return () => clearTimeout(newTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    dispatch(actions.setSearchTerm(''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div className={styles.container}>
      <input
        id="inputSearchTerm"
        value={searchTerm}
        placeholder="Search Answers in CME"
        className={styles.input}
        maxLength="128"
        autoComplete="false"
        autoFocus={window.innerWidth < 1040}
        onChange={e => dispatch(actions.setSearchTerm(e.target.value))}
      />
      <div
        role="button"
        tabIndex="0"
        style={{
          position: 'relative',
          left: '-33px',
          top: '5px'
        }}
        onClick={() => dispatch(actions.setSearchTerm(searchTerm))}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ')
            dispatch(actions.setSearchTerm(searchTerm))
        }}
      >
        <img
          alt="search"
          src={require('images/icon-search.png')}
          className={styles.searchimg}
        />
      </div>
    </div>
  )
}

export default SearchBar
